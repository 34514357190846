import React, { useState } from 'react';
import Logo from "./logo192.png"
// Modal component
const Modal = ({ isOpen, onClose, title, content }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" onClick={onClose}>
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white"
           onClick={e => e.stopPropagation()}>
        <div className="mt-3 text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">{title}</h3>
          <div className="mt-2 px-7 py-3">
            <p className="text-sm text-gray-500">{content}</p>
          </div>
          <div className="items-center px-4 py-3">
            <button
              onClick={onClose}
              className="px-4 py-2 bg-orange-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-300"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const App = () => {
  const [modalInfo, setModalInfo] = useState({ isOpen: false, title: '', content: '' });

  const openModal = (title, content) => {
    setModalInfo({ isOpen: true, title, content });
  };

  const closeModal = () => {
    setModalInfo({ ...modalInfo, isOpen: false });
  };

  return (
    <div className="home bg-white text-gray-600 min-h-screen">
      {/* Hero Section */}
      
      <section 
  className="relative w-full min-h-screen bg-cover bg-center" 
  style={{
    backgroundImage: `url(${Logo})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100vw',
    marginLeft: 'calc(-50vw + 50%)',
    marginRight: 'calc(-50vw + 50%)',
  }}>
  {/* Overlay to ensure text readability */}
  <div className="absolute inset-0 bg-black/50"></div>
  
  {/* Content */}
  <div className="absolute inset-0 flex items-center justify-center">
    <div className="container mx-auto text-center px-4 relative z-10">
      <h1 className="text-5xl font-bold mb-6 text-white animate-fade-in-down">
        Transforming Businesses with Innovative Solutions
      </h1>
      <p className="text-xl mb-12 text-white animate-fade-in-up">
        Portal Enterprises: Your Partner in Digital Evolution
      </p>
      <button
        onClick={() => console.log('Navigate to Contact')}
        className="bg-orange-500 text-white py-3 px-8 rounded-full text-lg font-semibold hover:bg-orange-600 transition duration-300 transform hover:scale-105 animate-pulse"
      >
        Get Started
      </button>
    </div>
  </div>
</section>

      {/* Features Section */}
      <section className="py-16 px-4">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold text-center mb-16 text-gray-800">Why Choose Portal Enterprises?</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12">
            {[
              { title: "Cutting-edge Technology", description: "Stay ahead with our state-of-the-art solutions", icon: "💡" },
              { title: "Expert Consultation", description: "Benefit from our years of industry experience", icon: "👥" },
              { title: "Tailored Approach", description: "Custom solutions designed for your unique needs", icon: "🎯" }
            ].map((feature, index) => (
              <div key={index} className="border border-gray-200 p-6 rounded-lg shadow-sm hover:shadow-lg transition duration-300 transform hover:-translate-y-1">
                <div className="text-4xl mb-4 text-orange-500">{feature.icon}</div>
                <h3 className="text-xl font-semibold mb-2 text-gray-800">{feature.title}</h3>
                <p className="text-gray-600">{feature.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Services Preview */}
      <section className="py-16 px-4 bg-gray-100">
        <div className="container mx-auto">
          <h2 className="text-3xl font-bold text-center mb-16 text-gray-800">Our Services</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {[
              { name: "Consulting Services", description: "Expert guidance to optimize your business processes and technology stack." },
              { name: "Software Development", description: "Custom software solutions tailored to your unique business needs." },
              { name: "Cloud Solutions", description: "Scalable and secure cloud infrastructure to power your digital transformation." },
              { name: "Data Analytics", description: "Turn your data into actionable insights with our advanced analytics services." },
              { name: "Cybersecurity", description: "Protect your digital assets with our comprehensive security solutions." },
              { name: "AI and Machine Learning", description: "Harness the power of AI to drive innovation and efficiency in your organization." }
            ].map((service, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-sm hover:shadow-md transition duration-300 transform hover:-translate-y-1">
                <h3 className="text-xl font-semibold mb-2 text-gray-800">{service.name}</h3>
                <p className="text-gray-600 mb-4">Brief overview of {service.name.toLowerCase()}.</p>
                <button 
                  onClick={() => openModal(service.name, service.description)}
                  className="text-orange-500 hover:text-orange-600 transition duration-300 hover:underline"
                >
                  Learn More →
                </button>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-20 px-4 bg-orange-500 text-white">
        <div className="container mx-auto text-center">
          <h2 className="text-3xl font-bold mb-6">Ready to Transform Your Business?</h2>
          <p className="text-xl mb-12">Let's work together to bring your vision to life.</p>
          <button 
            onClick={() => console.log('Navigate to Contact')} 
            className="bg-white text-orange-500 py-3 px-8 rounded-full text-lg font-semibold hover:bg-gray-100 transition duration-300 transform hover:scale-105"
          >
            Contact Us Today
          </button>
        </div>
      </section>

      {/* Modal */}
      <Modal
        isOpen={modalInfo.isOpen}
        onClose={closeModal}
        title={modalInfo.title}
        content={modalInfo.content}
      />
    </div>
  );
};

export default App;